import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Translation, useTranslation } from 'react-i18next';
import { QRCodeCanvas } from 'qrcode.react';
import { DeleteIcon, LinkIcon } from '@chakra-ui/icons'
import {
  Center,
  Flex,
  Spacer,
  Box, 
  Button, 
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  useDisclosure,
  Input,
  Radio,
  RadioGroup,
  Textarea,
  VStack,
  Stack,
  Skeleton,
  Drawer,
  DrawerOverlay,
  CloseButton,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FormControl,
  FormLabel,
  Switch,
  InputGroup,
  InputLeftAddon,
  Icon,
  useToast,
} from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useApi } from '../../api'
import { IBanner, ICargo, IChatMessage, IProfile, ISubMember, IUserCharacter } from '../../interfaces';
import { CharacterSwitch } from '../../components/CharacterSwitch';
import { BottomNavigation } from '../../components/BottomNavigation';
import { t } from 'i18next';
import { ProfileAvatarUploader } from '../../components/ProfileAvatarUploader';
import { Utils } from '../../utils'
import { BannerManagement } from '../../components/BannerManagement';
import '../../assets/css/swiper.css';
import { RouletteModal } from '../../components/RouletteModal';
import { WhiteButton } from '../../components/WhiteButton';
import { StickyNote } from '../../components/StickyNote';
import { BsInstagram, BsSkype, BsTelegram, BsTwitter, BsWechat } from 'react-icons/bs';
import { usePrivateApi } from '../../hooks/usePrivateApi';
import { SubMembersBlock } from '../../components/SubMembersBlock';
import { PrivateCrudApi } from '../../apis/private-crud-api';
import { ContentBlockWithHint } from '../../components/ContentBlockWithHint';
import { ConfirmAvator } from '../../components/ConfirmAvator';
import { IBlock } from '../../interfaces/IBlock';
import { useRecoilState } from 'recoil';
import { isSafeModeAtom } from '../../stores/view';
import { HintBlock } from '../../components/HintBlock';
import { LuFoldHorizontal, LuFoldVertical } from 'react-icons/lu';
import { PublicCrudApi } from '../../apis/public-crud-api';
import { IGeolocation } from '../../interfaces/IGeolocation';
import { IFollow } from '../../interfaces/IFollow';
import { CiGps } from 'react-icons/ci';
import Datepicker from 'react-tailwindcss-datepicker';
import { MdHealthAndSafety } from "react-icons/md";
import packageJson from "../../../package.json";
import dcardIcon from '../../assets/imgs/Dcard_Favicon_x520.png';
import { LanguageSelector } from '../../components/LanguageSelector';

export const MyProfilePage = () => {
  const toast = useToast();
  const wallChatroomId = '42b90196b487c54069097a68fe98ab6f';
  const { projectCode } = useParams();
  const api = useApi(projectCode);
  const privateApi = usePrivateApi();
  const marketApi = PrivateCrudApi('game', 'market/slave');
  const geolocationApi = PublicCrudApi('sns', 'geolocation');
  const geolocationsApi = PrivateCrudApi('sns', 'geolocations');

  const shareUrlBase =
    `${window.location.protocol}//${window.location.host}/${projectCode}/s`.replace(
      'tw.club',
      'er.club'
    );

  const navigate = useNavigate()
  const { t } = useTranslation();
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [isMounted, setIsMounted] = useState(false)
  const [profile, setProfile] = useState<IProfile>()
  const [nickname, setNickname] = useState<string>('')
  const [gender, setGender] = useState<string>()
  const [showGenderModal, setGenderModal] = useState<boolean>(false)
  const [genderLocker, setGenderLocker] = useState<boolean>(false)
  const [lookFor, setLookFor] = useState<string>()
  const [avator, setAvator] = useState<string|null>(null)
  const [introduction, setIntroduction] = useState<string>()
  const [listItems, setListItems] = useState<[]>()
  const [shareUrl, setShareUrl] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [userBanners, setUserBanners] = useState<IBanner[]>([])
  const [showDrawer, setDrawer] = useState<boolean>(false)
  const [memberNo, setMemberNo] = useState<string>()
  const [userCharacters, setUserCharacters] = useState<IUserCharacter[]>([]);
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [processing, setProcessing] = useState(false);
  const [subMembers, setSubMembers] = useState<ISubMember[]>([]);
  const [subMemberProcessing, setSubMemberProcessing] = useState<boolean>(false);
  const [blockedMembers, setBlockedMembers] = useState<IBlock[]>([]);
  const blockApi = PrivateCrudApi('sns', 'blocks');
  const [blockSecondHint, setBlockSecondHint] = useState<string>('');
  const [isSafeMode, setSafeMode] = useRecoilState<boolean>(isSafeModeAtom);
  const [geolocation, setGeolocation] = useState<IGeolocation>();
  const [myFollowMembers, setMyFollowMembers] = useState<IFollow[]>([]);
  const [birthday, setBirthday] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const updateUserCharacter = useCallback((e: any, idCharacter: Number) => {
    if (e.target.checked) {
      api.addUserCharacter(idCharacter, (cargo: ICargo) => {
        if (cargo.returnCode !== '000000') {

        }
      })
    } else {
      api.delUserCharacter(idCharacter, (cargo: ICargo) => {
        if (cargo.returnCode !== '000000') {
          
        }
      })
    }
  }, [api])

  const updateIntro = useCallback(() => {
    setIsLoading(true)
    if (introduction) {
      api.updateIntroduction(introduction, (cargo: ICargo) => {
        setIsLoading(false)
      })
    }
  }, [api, introduction])

  const updateBirthday = useCallback(() => {
    setIsLoading(true);
    if (birthday) {
      api.updateBirthday(birthday.startDate, (cargo: ICargo) => {
        setIsLoading(false);
      });
    }
  }, [api, birthday]);

  const updateSafeword = useCallback(() => {
    setIsLoading(true);
    if (profile?.safeword) {
      api.updateSafeword(profile?.safeword, (cargo: ICargo) => {
        setIsLoading(false);
      });
    }
  }, [api, birthday]);

  const updateLink = () => {
    if (profile) {
      setProcessing(true)
      privateApi.updateProfileLink(profile, () => {
        setProcessing(false)
      })
    }
  }

  useEffect(() => {
    if (!window.localStorage.getItem('memberId')
      || !window.localStorage.getItem('token') ) {
        navigate(`/${projectCode}/login`, { replace: true })
    }
    if (!isMounted) {
      if (!shareUrl) {
        api.getReferralCode((cargo: ICargo) => {
          if (cargo.success) {
            setMemberNo(cargo.info.simpleCode);
            setShareUrl(`${shareUrlBase}?c=${cargo.info.simpleCode}`);
          }
        })
      }
      if (!profile) {
        api.getProfile((cargo: ICargo) => {
          if (cargo.returnCode === '000000') {
            const userProfile: IProfile = cargo.info
            setProfile(cargo.info)
            setNickname(cargo.info['nickname'])
            setGender(cargo.info['multiGender'])
            setGenderLocker(cargo.info['multiGender'] !== undefined)
            setLookFor(`${userProfile.lookFor}`)
            setIntroduction(cargo.info['introduction'])
            setBirthday({
              startDate: cargo.info['birthday'],
              endDate: cargo.info['birthday'],
            });
            if (userProfile.avator) {
              const path = Utils().normalizeImagePath(userProfile.avator, projectCode);
              setAvator(path ? path : null)
              if (path) {
                window.localStorage.setItem('avator', path);
              }
            }
            if (userProfile.simpleCode) {
              window.localStorage.setItem('memberNo', userProfile.simpleCode);
            }
            if (userProfile.unqualifiedCount !== undefined && userProfile.unqualifiedCount > 10) {
              toast({
                title: 'Reminder',
                description: t('unqualifiedReminder'),
                status: 'info',
                duration: 9000,
                isClosable: true,
                position: 'top',
              });
            }
          }
        })
      }
      if (!listItems) {
        api.getCharacters((cargo: ICargo) => {
          let characterList: any = [];
          let characters = cargo.info
          api.getUserCharacters((cargo: ICargo) => {
            setUserCharacters(cargo.info)
            let userCharacters = cargo.info
            for (let character of characters) {
              character['checked'] = false
              for (let userCharacter of userCharacters) {
                if (userCharacter['idCharacter'] === character['idCharacter']) {
                  character['checked'] = true
                  break
                }
              }
              characterList.push(character)
            }
            const items = characterList.map((character: any) => (
              <CharacterSwitch
                key={character['idCharacter']}
                readOnly={false}
                title={t(character['title'])}
                level={character['level']}
                initValue={character['checked']}
                onValueChange={(e: any) => {
                  updateUserCharacter(e, character['idCharacter']);
                }}
              ></CharacterSwitch>
            ));
            setListItems(items)
          })
        })
      }
      setIsMounted(true)
    }
  }, [api, gender, introduction, isMounted, listItems, navigate, nickname, profile, projectCode, shareUrl, shareUrlBase, updateUserCharacter]);

  const fetchBanners = () => {
    if (memberNo) {
      api.getUserBanners(memberNo, (cargo: ICargo) => {
        setUserBanners(cargo.info);
      })
    }
  }

  const fetchChatroomMessages = () => {
    if (memberNo) {
      api.getChatMessages(wallChatroomId, memberNo, (cargo: ICargo) => {
        if (cargo.success) {
          setMessages(cargo.info.reverse())
        }
      })
    }
  }

  const fetchSubMembers = () => {
    setSubMemberProcessing(false);
    if (!memberNo) return;
    api.getSubMembers(memberNo, (cargo: ICargo) => {
      setSubMembers(cargo.info['subNodes']);
    })
  }

  const fetchBlockMembers = () => {
    blockApi.getAll().then((data) => setBlockedMembers(data as IBlock[]))
  }

  const fetchMyFollowMembers = () => {
    privateApi.getMyFollow((cargo: ICargo) => {
      if (cargo.success) {
        setMyFollowMembers(cargo.info);
      }
    });
  };

  useEffect(() => {
    fetchBanners()
    fetchChatroomMessages()
    fetchSubMembers()
    fetchBlockMembers()
    fetchMyFollowMembers()
    if (memberNo) {
      geolocationApi.getById(memberNo).then(data => setGeolocation(data as IGeolocation)).catch((e) => console.log(e))
    }
  }, [memberNo])

  useEffect(() => {
    fetchMyFollowMembers();
  }, [isSafeMode]);

  useEffect(() => {
    userBanners.forEach(banner => {
      if (!banner.height || !banner.width) {
        Utils().getImgSize(banner.image, (size: { width: number, height: number }) => {
          privateApi.updateBannerSize(banner.idBanner, size.width, size.height);
        });
      }
    })
  }, [userBanners])

  const OverlayConfig = () => (
    <ModalOverlay
      bg='none'
      backdropFilter='auto'
      backdropInvert='80%'
      backdropBlur='2px'
    />
  )

  const [overlay, setOverlay] = useState(<OverlayConfig />)

  const shareTo = () => {
    window.navigator.share({
      title: 'Profile Share',
      url: shareUrl
    }).then(() => {
      onClose()
    })
    .catch(console.error);
  }

  useEffect(() => {
    window.localStorage.setItem('isSafeMode', `${isSafeMode}`);
  }, [isSafeMode])

  return (
    <>
      <Flex>
        <div className="text-left pt-3 aspect-square m-3">
          <span
            className={`text-xs ${avator ? 'text-slate-600' : 'text-rose-600'}`}
          >
            {t('uploadSquarePhoto')}
          </span>
          <ProfileAvatarUploader
            image={avator}
            onUploaded={(cargo: ICargo, isGraphic) => {
              if (cargo.returnCode === '000000') {
                api.updateAvator(cargo.info['file'], !isGraphic);
                const path = Utils().normalizeImagePath(
                  cargo.info['file'],
                  projectCode
                );
                setAvator(path ? path : null);
              }
            }}
            error={!profile?.qualify}
          />
        </div>
        <Spacer />
        <Box p="4">
          <Button
            rightIcon={<LinkIcon />}
            colorScheme="teal"
            variant="outline"
            onClick={() => {
              setOverlay(<OverlayConfig />);
              onOpen();
            }}
          >
            <Translation>{(t) => t('shareProfile')}</Translation>
          </Button>
        </Box>
      </Flex>
      <div className="pl-3 mb-3 text-xs text-slate-700">
        {profile?.blur && (
          <div>
            <div>{t('reUploadIfMosaic')}</div>
            <div className="text-xs text-slate-500">
              AI辨識率尚待加強，勿壓印。建議使用有人的生活照。
            </div>
          </div>
        )}
        {!profile?.qualify && (
          <div>
            <div>{t('reUploadIfUnqualified')}</div>
            <div className="text-xs text-red-400">
              {`${t('numberOfUnqualified')} ${profile?.unqualifiedCount}/20`}
            </div>
          </div>
        )}
      </div>
      <div className="px-5 py-5 shadow rounded-xl">
        <div
          className={` text-xs ${
            nickname ? 'text-slate-600' : 'text-rose-600'
          }`}
        >
          {t('nickname')}
        </div>
        <Input
          variant="flushed"
          value={nickname}
          placeholder={t('yourNickname')}
          onChange={(e) => {
            setNickname(e.target.value);
            api.updateNickname(e.target.value, (cargo: ICargo) => {});
          }}
        />
        <div className="mt-3 grid grid-cols-2">
          <div>
            <div>{t('myProperties')}</div>
            <RadioGroup
              isDisabled={genderLocker}
              className="mt-3"
              onChange={(value) => {
                setGender(value);
                setGenderModal(true);
              }}
              value={gender}
            >
              <VStack align={'left'}>
                <Radio value="1">
                  <Translation>{(t) => t('maleS')}</Translation>
                </Radio>
                <Radio value="2">
                  <Translation>{(t) => t('femaleS')}</Translation>
                </Radio>
                <Radio value="3">
                  <Translation>{(t) => t('maleM')}</Translation>
                </Radio>
                <Radio value="4">
                  <Translation>{(t) => t('femaleM')}</Translation>
                </Radio>
                <Radio value="5">
                  <Translation>{(t) => t('maleSW')}</Translation>
                </Radio>
                <Radio value="6">
                  <Translation>{(t) => t('femaleSW')}</Translation>
                </Radio>
              </VStack>
            </RadioGroup>
          </div>
          <div>
            <div>{t('lookingFor')}</div>
            <RadioGroup
              className="mt-3"
              onChange={(value) => {
                setLookFor(value);
                api.updateLookFor(value, (cargo: ICargo) => {});
              }}
              value={lookFor}
            >
              <VStack align={'left'}>
                <Radio value="0">{t('noLimit')}</Radio>
                <Radio value="1">
                  <Translation>{(t) => t('maleS')}</Translation>
                </Radio>
                <Radio value="2">
                  <Translation>{(t) => t('femaleS')}</Translation>
                </Radio>
                <Radio value="3">
                  <Translation>{(t) => t('maleM')}</Translation>
                </Radio>
                <Radio value="4">
                  <Translation>{(t) => t('femaleM')}</Translation>
                </Radio>
                <Radio value="5">
                  <Translation>{(t) => t('maleSW')}</Translation>
                </Radio>
                <Radio value="6">
                  <Translation>{(t) => t('femaleSW')}</Translation>
                </Radio>
              </VStack>
            </RadioGroup>
          </div>
        </div>
        {/* <div className='text-right text-xs pt-2'>上傳頭像請務必遵守當地法令以免觸法</div> */}
        <div
          className={`text-right text-xs pt-2 ${
            !nickname || !avator ? 'text-rose-600' : 'text-slate-600'
          }`}
        >
          {t('filledInCompleted')}
        </div>
      </div>
      {profile && (
        <div className="p-3">
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="email-alerts" mb="0">
              {t('rainbow')}
            </FormLabel>
            <Spacer />
            <Switch
              size={'lg'}
              colorScheme={'yellow'}
              isChecked={profile.isRainbow}
              onChange={(e) => {
                api.updateIsRainbow(e.target.checked);
                setProfile({ ...profile, isRainbow: e.target.checked });
              }}
            />
          </FormControl>
        </div>
      )}
      <div className="p-3">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="safe-mode-switch" mb="0">
            {t('safeMode')}
          </FormLabel>
          <Spacer />
          <Switch
            id="safe-mode-switch"
            size={'lg'}
            colorScheme={'yellow'}
            isChecked={isSafeMode}
            onChange={(e) => {
              setSafeMode((isSafeMode) => !isSafeMode);
            }}
          />
        </FormControl>
      </div>
      {profile && (
        <div className="p-3">
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="email-alerts" mb="0">
              {t('closeProfile')}
            </FormLabel>
            <Spacer />
            <Switch
              size={'lg'}
              colorScheme={'yellow'}
              isChecked={profile.closed}
              onChange={(e) => {
                api.updateCloseProfile(e.target.checked);
                setProfile({ ...profile, closed: e.target.checked });
              }}
            />
          </FormControl>
        </div>
      )}
      <div className="mt-5 grid grid-cols-2 gap-3">
        <RouletteModal
          userCharacters={userCharacters}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        />
        <div>
          <WhiteButton
            text={t('homePage')}
            onClick={() => {
              navigate(
                `/${projectCode?.toLowerCase()}/s?c=${profile?.simpleCode}`
              );
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <div className="mt-5 ml-3">{t('preference')}</div>
          <div className="px-5 py-1 shadow rounded-xl">{listItems}</div>
          <div className="text-xs ml-3 mt-1">{t('characterFeedback')}</div>
        </div>
        <div>
          <div className="mt-5 ml-3">{t('myBirthday')}</div>
          <div className="my-3 flex flex-col gap-3 shadow rounded-xl p-3">
            <div className="shadow rounded-xl">
              <Datepicker
                primaryColor="yellow"
                asSingle={true}
                value={birthday}
                onChange={(e) => {
                  setBirthday(e);
                }}
              />
              <Button
                isLoading={isLoading}
                colorScheme={'white'}
                shadow="lg"
                color={'green.900'}
                width="100%"
                onClick={updateBirthday}
              >
                <Translation>{(t) => t('save')}</Translation>
              </Button>
            </div>
          </div>
          <div className="mt-5 ml-3">{t('safeword')}</div>
          <div className="my-3 flex flex-col gap-3 shadow rounded-xl p-3">
            <div className="rounded-xl">
              <Stack spacing={4}>
                <InputGroup>
                  <InputLeftAddon
                    children={<Icon as={MdHealthAndSafety} fontSize={'xl'} />}
                    color={'gray.500'}
                  />
                  <Input
                    type="text"
                    value={profile?.safeword || ''}
                    onChange={(e) => {
                      setProfile({ ...profile, safeword: e.target.value });
                    }}
                  />
                </InputGroup>
                <Button
                  isLoading={isLoading}
                  colorScheme={'white'}
                  shadow="lg"
                  color={'green.900'}
                  width="100%"
                  onClick={updateSafeword}
                >
                  <Translation>{(t) => t('save')}</Translation>
                </Button>
              </Stack>
            </div>
          </div>

          <div className="mt-5 ml-3">{t('selfIntroduction')}</div>
          <div className="shadow rounded-xl">
            <Textarea
              onChange={(e) => {
                setIntroduction(e.target.value);
              }}
              value={introduction}
              rows={15}
              placeholder={t('provideIntro')}
              roundedTop={'lg'}
            />
            <div className="p-3">
              <Button
                isLoading={isLoading}
                colorScheme={'white'}
                shadow="lg"
                color={'green.900'}
                width="100%"
                onClick={updateIntro}
              >
                <Translation>{(t) => t('save')}</Translation>
              </Button>
            </div>
            <div className="pl-3 pb-3 text-xs text-slate-700">
              <div>{t('introHint')}</div>
              <div className="text-xs text-slate-500">
                Chat GPT應該可以協助你完成
              </div>
            </div>
          </div>
          <div className="my-3 flex flex-col gap-3 shadow rounded-xl p-3">
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftAddon
                  children={<Icon as={BsSkype} fontSize={'xl'} />}
                  color={'gray.500'}
                />
                <Input
                  type="text"
                  value={profile?.skypeId || ''}
                  onChange={(e) => {
                    setProfile({ ...profile, skypeId: e.target.value });
                  }}
                />
              </InputGroup>
            </Stack>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftAddon
                  children={
                    <Icon as={BsWechat} fontSize={'xl'} color={'gray.500'} />
                  }
                />
                <Input
                  type="text"
                  value={profile?.wechatId || ''}
                  onChange={(e) => {
                    setProfile({ ...profile, wechatId: e.target.value });
                  }}
                />
              </InputGroup>
            </Stack>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftAddon
                  children={
                    <Icon as={BsTelegram} fontSize={'xl'} color={'gray.500'} />
                  }
                />
                <Input
                  type="text"
                  value={profile?.telegramId || ''}
                  onChange={(e) => {
                    setProfile({ ...profile, telegramId: e.target.value });
                  }}
                />
              </InputGroup>
            </Stack>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftAddon
                  children={
                    <Icon as={BsTwitter} fontSize={'xl'} color={'gray.500'} />
                  }
                />
                <Input
                  type="text"
                  value={profile?.twitterId || ''}
                  onChange={(e) => {
                    setProfile({ ...profile, twitterId: e.target.value });
                  }}
                />
              </InputGroup>
            </Stack>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftAddon
                  children={
                    <Icon as={BsInstagram} fontSize={'xl'} color={'gray.500'} />
                  }
                />
                <Input
                  type="text"
                  value={profile?.instagramId || ''}
                  onChange={(e) => {
                    setProfile({ ...profile, instagramId: e.target.value });
                  }}
                />
              </InputGroup>
            </Stack>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftAddon children={<img src={dcardIcon} width={21} />} />
                <Input
                  type="text"
                  value={profile?.dcardId || ''}
                  onChange={(e) => {
                    setProfile({ ...profile, dcardId: e.target.value });
                  }}
                />
              </InputGroup>
            </Stack>
            <Button
              isLoading={processing}
              colorScheme={'white'}
              shadow="lg"
              color={'green.900'}
              width="100%"
              onClick={updateLink}
            >
              <Translation>{(t) => t('save')}</Translation>
            </Button>
            <div className="pl-1 mt-1 text-xs text-slate-700">
              <div>{t('avoidToShare')}!</div>
              <div className="text-xs text-slate-500">
                {t('unlessYouReallyWantToPublish')}
              </div>
            </div>
          </div>
          <div className="mt-5 ml-3">{t('coordinate')}</div>
          <div className="my-3 flex flex-col gap-3 shadow rounded-xl p-3">
            <div className="flex justify-end cursor-pointer">
              <CiGps
                onClick={() => {
                  if ('geolocation' in navigator) {
                    // Request the user's location

                    navigator.geolocation.getCurrentPosition(
                      (position) => {
                        setGeolocation({
                          latitude: position.coords.latitude,
                          longitude: position.coords.longitude,
                        });
                      },
                      (error) => {
                        console.error('Error getting location:', error.message);
                        toast({
                          title: 'Error getting location',
                          description: error.message,
                          status: 'error',
                          duration: 9000,
                          isClosable: true,
                        });
                      }
                    );
                  } else {
                    console.error(
                      'Geolocation is not supported by your browser'
                    );
                    toast({
                      title: 'Error',
                      description:
                        'Geolocation is not supported by your browser',
                      status: 'error',
                      duration: 9000,
                      isClosable: true,
                    });
                  }
                }}
              />
            </div>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftAddon
                  children={<Icon as={LuFoldVertical} fontSize={'xl'} />}
                  color={'gray.500'}
                />
                <Input
                  type="text"
                  value={geolocation?.latitude || ''}
                  placeholder={t('latitude')}
                  onChange={(e) => {
                    const tmpLatLng: string = e.target.value;
                    if (tmpLatLng.includes(',')) {
                      setGeolocation(Utils().geolocationBuilder(tmpLatLng));
                    } else {
                      setGeolocation({
                        ...geolocation,
                        latitude: Number(tmpLatLng),
                      });
                    }
                  }}
                />
              </InputGroup>
            </Stack>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftAddon
                  children={
                    <Icon
                      as={LuFoldHorizontal}
                      fontSize={'xl'}
                      color={'gray.500'}
                    />
                  }
                />
                <Input
                  type="text"
                  value={geolocation?.longitude || ''}
                  placeholder={t('longitude')}
                  onChange={(e) => {
                    const tmpLatLng: string = e.target.value;
                    if (tmpLatLng.includes(',')) {
                      setGeolocation(Utils().geolocationBuilder(tmpLatLng));
                    } else {
                      setGeolocation({
                        ...geolocation,
                        longitude: Number(tmpLatLng),
                      });
                    }
                  }}
                />
              </InputGroup>
            </Stack>
            <Button
              isLoading={processing}
              colorScheme={'white'}
              shadow="lg"
              color={'green.900'}
              width="100%"
              onClick={() => {
                setProcessing(true);
                const geo = Utils().geolocationBuilder(
                  `${geolocation?.latitude},${geolocation?.longitude}`
                );
                if (projectCode) {
                  geolocationsApi
                    .update(projectCode.toUpperCase(), geo)
                    .then(() => setProcessing(false));
                }
              }}
            >
              <Translation>{(t) => t('save')}</Translation>
            </Button>
            <HintBlock
              firstLine={t('notRequired')}
              secondLine={t('isAbleToCopyFromGoogleMap')}
            />
          </div>
          {profile && (
            <div>
              <SubMembersBlock
                blockTitle={t('iAmFollowing')}
                hint=""
                noDataHint={t('noFollower')}
                actionPopup={false}
                parentProfile={profile}
                subMembers={myFollowMembers.map((follow) => {
                  const subMember: ISubMember = {
                    profile: follow.profile,
                    subNode: [],
                  };
                  return subMember;
                })}
                processing={false}
              />
            </div>
          )}
          {profile && (
            <div>
              <SubMembersBlock
                blockTitle={t('yourSlaves')}
                hint={t('freeSlaveHint')}
                noDataHint={t('noSlave')}
                actionPopup={true}
                parentProfile={profile}
                subMembers={subMembers}
                onSale={(subMemberNo) => {
                  setSubMemberProcessing(true);
                  marketApi
                    .create({ simpleCode: subMemberNo })
                    .then(fetchSubMembers);
                }}
                onFree={(subMemberNo) => {
                  setSubMemberProcessing(true);
                  privateApi.layoffsSubMember(subMemberNo, fetchSubMembers);
                }}
                processing={subMemberProcessing}
              />
            </div>
          )}
          <ContentBlockWithHint
            title={t('blockedByMe')}
            firstLineHint={t('unblockHint')}
            secondLineHint={blockSecondHint}
          >
            <div>
              {blockedMembers.map((blockMember) => {
                return (
                  <span
                    key={blockMember.idBlock}
                    className={`hover:opacity-100 ${
                      blockSecondHint ? 'opacity-100' : 'blur-sm opacity-70'
                    } hover:blur-0 cursor-pointer`}
                  >
                    <ConfirmAvator
                      profile={blockMember.profile}
                      onClick={() => {
                        setBlockSecondHint('再確認。可解除封鎖');
                      }}
                      onConfirm={() => {
                        if (blockMember.idBlock) {
                          blockApi.delete(blockMember.idBlock).then(() => {
                            setBlockSecondHint('');
                            fetchBlockMembers();
                          });
                        }
                      }}
                    />
                  </span>
                );
              })}
            </div>
          </ContentBlockWithHint>
          <div>
            <div className="gap-3 columns-1 lg:columns-1">
              <div className="w-full inline-block mb-3">
                {messages.map((message, index) => {
                  const profile = message.profile;
                  if (!profile) return null;
                  return (
                    <StickyNote
                      key={`m_${message.idMessage}_${index}`}
                      profile={profile}
                      content={message.message}
                      timestamp={message.dateAdd}
                      replyId={message.idChatroomMessage}
                      replyCount={message.threadCount}
                      deletable={true}
                      onDelete={fetchChatroomMessages}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 shadow rounded-xl">
        {userBanners.length === 0 ? (
          <Stack>
            <Skeleton height="14em" roundedTop={'lg'} />
          </Stack>
        ) : (
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={2}
            className="rounded-t-xl"
          >
            {userBanners.map((banner) => {
              return (
                <SwiperSlide key={banner.idBanner}>
                  <div>
                    <span
                      className="absolute top-3 right-3 bg-white w-8 h-8 rounded-full cursor-pointer opacity-60 hover:opacity-95"
                      onClick={() => {
                        api.delUserBanner(banner.idBanner, () => {
                          fetchBanners();
                        });
                      }}
                    >
                      <DeleteIcon />
                    </span>
                    <img
                      src={Utils().normalizeImagePath(
                        banner.image,
                        projectCode
                      )}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        <div className="flex">
          <Button
            colorScheme={'white'}
            shadow="lg"
            color={'green.900'}
            width="100%"
            onClick={() => setDrawer(true)}
          >
            <Translation>{(t) => t('sharePhotos')}</Translation>
          </Button>
          <Button
            colorScheme={'white'}
            shadow="lg"
            color={'green.900'}
            width="100%"
            onClick={() => {
              navigate(`/${projectCode}/albums`);
            }}
          >
            <Translation>{(t) => t('albumMng')}</Translation>
          </Button>
        </div>
      </div>
      <div className="pl-3 mt-3 text-xs text-slate-700">
        <div>{t('attractPhotosHint')}</div>
        <div className="text-xs text-slate-500">{t('followLawHint')}</div>
        <div className="text-xs text-slate-400">{t('privateShareHint')}</div>
      </div>
      <div className="pt-5"></div>
      <div className="pt-3 mb-5">
        <Button
          isLoading={isLoading}
          colorScheme={'white'}
          shadow="lg"
          color={'green.900'}
          width="100%"
          onClick={() => {
            setIsLoading(true);
            api.logout((cargo: ICargo) => {
              setIsLoading(false);
              if (cargo.returnCode === '000000') {
                window.location.href = `/${projectCode}/login`;
              }
            });
          }}
        >
          <Translation>{(t) => t('logout')}</Translation>
        </Button>
      </div>
      <LanguageSelector />
      <div className="h-[100px] text-xs text-gray-400 text-right">
        Version {packageJson.version}
      </div>
      <Modal size={'xs'} isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader>
            <Translation>{(t) => t('shareProfile')}</Translation>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={6}>
            <Center>
              <QRCodeCanvas value={shareUrl} />
            </Center>
            <Center>
              <Text className="mt-5">
                <a href={shareUrl}>{shareUrl}</a>
              </Text>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button onClick={shareTo}>Share</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showGenderModal}
        onClose={() => {
          setGender(undefined);
          setGenderModal(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>請再次確認</ModalHeader>
          <ModalCloseButton />
          <ModalBody>屬性設定後將無法變更，請務必確認好您的屬性。</ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => {
                setGender(undefined);
                setGenderModal(false);
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() => {
                if (!gender) return;
                setGenderModal(false);
                api.updateGender(gender, (cargo: ICargo) => {
                  if (cargo.success) {
                    setGenderLocker(true);
                  }
                });
              }}
            >
              {t('confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer
        placement={'bottom'}
        onClose={() => setDrawer(false)}
        isOpen={showDrawer}
      >
        <DrawerOverlay />
        <DrawerContent className="rounded-t">
          <DrawerHeader borderBottomWidth="1px">
            <Flex>
              <Spacer></Spacer>
              <CloseButton onClick={() => setDrawer(false)} />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <BannerManagement
              memberNo={memberNo}
              onSuccess={(file) => {
                setUserBanners([
                  ...userBanners,
                  {
                    idBanner: userBanners?.length + 1,
                    image: file,
                  },
                ]);
              }}
            />
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" onClick={() => setDrawer(false)}>
              {t('close')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  );
};