import md5 from "md5";
import { IProfile } from "./interfaces";
import { createAvatar } from '@dicebear/core';
import { avataaars } from '@dicebear/collection';
import { IGeolocation } from "./interfaces/IGeolocation";
import Geohash from "latlon-geohash";
import moment from "moment";

export const Utils = () => {
  const isSafeMode = window.localStorage.getItem('isSafeMode') === "true";
  
  return {
    avatorUrlBuilder(profile?: IProfile | null) {
      if (isSafeMode || !profile) {
        const avatar = createAvatar(avataaars, {
          seed: profile?.nickname || `${profile?.idProfile}` || this.getUuid(),
        });
        return avatar.toDataUri()
      }
      return this.normalizeImagePath(
        profile.avator,
        profile.projectCode || this.getProjectCode()
      );
    },
    isLogin() {
      return localStorage.getItem('token') ? true : false;
    },
    normalizeImagePath(path?: string, projectCode?: string) {
      if (!path) return path;
      const protocol = window.location.protocol;
      const host = window.location.host;
      if (path.indexOf('http') > -1) {
        return path;
      } else {
        if (projectCode) {
          return `${protocol}//${host}/files/${projectCode.toLowerCase()}/${path}`;
        }
        return `${protocol}//${host}/files/${path}`;
      }
    },
    getProjectCode() {
      return window.location.pathname.split('/')[1].toUpperCase();
    },
    getTimestamp() {
      return new Date().getTime();
    },
    getTimeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    datetimeFormatter(datetime?: string | Date, pattern?: string) {
      if (!datetime) return '';
      try {
        return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
      } catch {
        return '';
      }
    },
    uuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    getUuid() {
      return this.uuid();
    },
    md5(str: string) {
      return md5(str);
    },
    calculateSaturation(imageData: ImageData) {
      let sumSaturation = 0;

      for (let i = 0; i < imageData.data.length; i += 4) {
        const [r, g, b] = [
          Number(imageData.data[i]),
          Number(imageData.data[i + 1]),
          Number(imageData.data[i + 2]),
        ];

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);

        const delta = max - min;

        const l = (max + min) / 2;
        const s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        sumSaturation += Math.round(s * 100) / 100;
      }
      const averageSaturation = sumSaturation / (imageData.data.length / 4); // Average over all pixels
      return averageSaturation;
    },
    calculateHistogram(imageData: ImageData): number[] {
      const histogram: number[] = new Array(256).fill(0);

      for (let i = 0; i < imageData.data.length; i += 4) {
        const [r, g, b] = [
          Number(imageData.data[i]),
          Number(imageData.data[i + 1]),
          Number(imageData.data[i + 2]),
        ];
        const intensity = Math.round((r + g + b) / 3);
        histogram[intensity]++;
      }

      return histogram;
    },
    calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
      // Convert latitude and longitude from degrees to radians
      const radLat1 = (Math.PI * lat1) / 180;
      const radLon1 = (Math.PI * lon1) / 180;
      const radLat2 = (Math.PI * lat2) / 180;
      const radLon2 = (Math.PI * lon2) / 180;

      // Haversine formula
      const dLat = radLat2 - radLat1;
      const dLon = radLon2 - radLon1;
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(radLat1) *
          Math.cos(radLat2) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      // Radius of the Earth in kilometers (change to 3959 for miles)
      const R = 6371;

      // Calculate the distance
      const distance = R * c;

      return distance;
    },
    geolocationBuilder(strLatLng: string) {
      const arrLatLng = strLatLng.split(',');
      const lat = Number(arrLatLng[0]);
      const lng = Number(arrLatLng[1]);
      const geoHash = Geohash.encode(lat, lng);
      const geo: IGeolocation = {
        latitude: lat,
        longitude: lng,
        geohash: geoHash,
      };
      return geo;
    },
    getImgDataFromPath(url: string, scaleFactor: number, cb: Function) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';

      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = img.width * scaleFactor;
        elem.height = img.height * scaleFactor;

        const ctx = elem.getContext('2d');
        if (ctx) {
          ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            0,
            0,
            elem.width,
            elem.height
          );
          const imageData = ctx.getImageData(0, 0, elem.width, elem.height);
          cb(imageData);
        }
      };
      const path = Utils().normalizeImagePath(url, this.getProjectCode());
      if (path) {
        img.src = path;
      }
    },
    getImgSize(url: string, cb: Function) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        cb({ width: img.width, height: img.height });
      };
      const path = Utils().normalizeImagePath(url, this.getProjectCode());
      if (path) {
        img.src = path;
      }
    },
    isGraphic(imageData: ImageData): boolean {
      const histogram = this.calculateHistogram(imageData);
      const totalPixels = Object.values(histogram).reduce(
        (sum, count) => sum + count,
        0
      );

      // Calculate the percentage of pixels in the tails of the histogram
      const tailThreshold = 0.4; // Threshold for considering tails of histogram
      const tailPixels = Object.entries(histogram)
        .filter(
          ([intensity, count]) =>
            parseInt(intensity) <= 25 || parseInt(intensity) >= 230
        )
        .reduce((sum, [, count]) => sum + count, 0);
      const tailPercentage = tailPixels / totalPixels;
      return tailPercentage > tailThreshold;
    },
  };
}