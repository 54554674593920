import { Divider, Icon } from "@chakra-ui/react";
import { t } from "i18next";
import { BsSkype, BsTwitter } from "react-icons/bs";

interface LookForFilterProps {
  setLookFor: (lookFor: number | string) => void;
}

export const LookForFilter = ({setLookFor}: LookForFilterProps) => {
  return (
    <div className="flex">
      <span className="whitespace-nowrap text-xs py-1 pl-1">
        {t('lookingForFilter')}
      </span>
      <Divider orientation="vertical" className="pr-1 divide-gray-600" />
      <div className="flex overflow-x-scroll no-scrollbar whitespace-nowrap gap-1 mb-4">
        <span
          className="px-3 py-1 inline-block rounded-full border border-gray-300 text-xs text-gray-500 text-center cursor-pointer hover:bg-slate-300 hover:text-white duration-300"
          onClick={() => {
            setLookFor(1);
          }}
        >
          {t('lookingForMaleS')}
        </span>
        <span
          className="px-3 py-1 inline-block rounded-full border border-gray-300 text-xs text-gray-500 text-center cursor-pointer hover:bg-slate-300 hover:text-white duration-300"
          onClick={() => {
            setLookFor(3);
          }}
        >
          {t('lookingForMaleM')}
        </span>
        <span
          className="px-3 py-1 inline-block rounded-full border border-gray-300 text-xs text-gray-500 text-center cursor-pointer hover:bg-slate-300 hover:text-white duration-300"
          onClick={() => {
            setLookFor(2);
          }}
        >
          {t('lookingForFemaleS')}
        </span>
        <span
          className="px-3 py-1 inline-block rounded-full border border-gray-300 text-xs text-gray-500 text-center cursor-pointer hover:bg-slate-300 hover:text-white duration-300"
          onClick={() => {
            setLookFor(4);
          }}
        >
          {t('lookingForFemaleM')}
        </span>
        <span
          className="px-3 py-1 inline-block rounded-full border border-gray-300 text-xs text-gray-500 text-center cursor-pointer hover:bg-slate-300 hover:text-white duration-300"
          onClick={() => {
            setLookFor('SKYPE');
          }}
        >
          <Icon as={BsSkype} className="m-1" />
        </span>
        <span
          className="px-3 py-1 inline-block rounded-full border border-gray-300 text-xs text-gray-500 text-center cursor-pointer hover:bg-slate-300 hover:text-white duration-300"
          onClick={() => {
            setLookFor('TWITTER');
          }}
        >
          <Icon as={BsTwitter} className="m-1" />
        </span>
      </div>
    </div>
  );
}