import { ICargo, IProfile } from './interfaces';
import { Utils } from './utils';

export const useApi = (projectCode: string | undefined) => {
  return {
    projectCode: projectCode ? projectCode.toUpperCase() : 'DEF',
    getGeneralHeader() {
      if (!window.localStorage.getItem('token')) {
        window.location.href = `/${this.projectCode.toLowerCase()}/login`;
        return;
      }
      return {
        'Content-Type': 'application/json',
        'X-Access-Token': window.localStorage.getItem('token') || 'DummyToken',
        'X-Member-ID': this.getMemberId() || 'DummyMemberId',
      };
    },
    getMemberId() {
      return window.localStorage.getItem('memberId');
    },
    publicGet(endPoint: string, cb?: Function) {
      fetch(`/api/v6/public${endPoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          if (cargo.returnCode === '000000') {
            cargo.success = true;
          }
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    publicPost(endPoint: string, body: any, cb?: Function) {
      fetch(`/api/v6/public${endPoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          if (cargo.returnCode === '000000') {
            cargo.success = true;
          }
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    privateGet(endPoint: string, cb?: Function) {
      if (!Utils().isLogin()) {
        window.location.href = `/${Utils().getProjectCode}/login`;
      }
      fetch(`/api/v6/private${endPoint}`, {
        method: 'GET',
        headers: this.getGeneralHeader(),
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          if (cargo.returnCode === '000000') {
            cargo.success = true;
          }
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    privateMethod(endPoint: string, method: string, body: any, cb?: Function) {
      fetch(`/api/v6/private${endPoint}`, {
        method: method.toUpperCase(),
        headers: this.getGeneralHeader(),
        body: JSON.stringify(body),
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          if (cargo.returnCode === '000000') {
            cargo.success = true;
          }
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    privatePost(endPoint: string, body: any, cb?: Function) {
      this.privateMethod(endPoint, 'POST', body, cb);
    },
    privatePatch(endPoint: string, body: any, cb?: Function) {
      this.privateMethod(endPoint, 'PATCH', body, cb);
    },
    privatePut(endPoint: string, body: any, cb?: Function) {
      this.privateMethod(endPoint, 'PUT', body, cb);
    },
    privateDelete(endPoint: string, body: any, cb?: Function) {
      this.privateMethod(endPoint, 'DELETE', body, cb);
    },
    addUserBanner(
      image: string,
      pickable: boolean,
      width: Number,
      height: Number,
      cb?: Function
    ) {
      this.privatePost(
        `/core/banners/${this.getMemberId()}`,
        { image, pickable, width, height },
        cb
      );
    },
    confirmMission(idMission: number, remark: string, cb?: Function) {
      this.privatePut(
        `/sns/tasks/missions/confirm/${this.getMemberId()}/${idMission}`,
        { remark },
        cb
      );
    },
    createTask(subject: string, description: string, cb?: Function) {
      this.privatePost(
        `/sns/tasks/${this.getMemberId()}`,
        { subject, description },
        cb
      );
    },
    delUserBanner(idBanner: Number, cb?: Function) {
      this.privateDelete(
        `/core/banners/${idBanner}/${this.getMemberId()}`,
        {},
        cb
      );
    },
    getBanners(cb?: Function) {
      this.publicGet(`/core/banners/${this.projectCode}`, cb);
    },
    getChatMessageDetail(idChatroomMessage: number | string, cb?: Function) {
      this.publicGet(`/sns/chatroom/messages/${idChatroomMessage}`, cb);
    },
    getChatMessages(chatroomId: string, memberNo: string, cb?: Function) {
      this.publicGet(`/sns/chatrooms/${chatroomId}/messages/${memberNo}`, cb);
    },
    getChatMessageCount(memberNo: string, cb?: Function) {
      this.publicGet(`/sns/chatrooms/messages/${memberNo}/count`, cb);
    },
    getCharacters(cb?: Function) {
      this.publicGet(`/sns/characters/${this.projectCode}`, cb);
    },
    getCharacterUsers(idCharacter: Number, cb?: Function) {
      this.publicGet(`/sns/characters/users/${idCharacter}`, cb);
    },
    getCoinInfo(cb?: Function) {
      this.privateGet(`/core/pvs/${this.getMemberId()}`, cb);
    },
    getGuestbooks(cb?: Function) {
      this.privateGet(`/sns/guestbooks/${this.getMemberId()}`, cb);
    },
    getGuestbooksCount(cb?: Function) {
      this.privateGet(`/sns/guestbooks/count/${this.getMemberId()}`, cb);
    },
    getHierarchyHistory(memberNo: string, cb?: Function) {
      this.publicGet(`/sns/hierarchy/history/${memberNo}`, cb);
    },
    getHotProfile(projectCode: string, pageSize: number, cb?: Function) {
      this.publicGet(
        `/core/profiles/${projectCode.toUpperCase()}/hot?pageSize=${pageSize}`,
        cb
      );
    },
    getIntroProfiles(projectCode: string, pageSize: number, cb?: Function) {
      this.publicGet(
        `/core/profiles/${projectCode.toUpperCase()}/intro?pageSize=${pageSize}`,
        cb
      );
    },
    getLastChatMessageProfiles(
      projectCode: string,
      pageSize: number,
      cb?: Function
    ) {
      this.publicGet(
        `/core/profiles/${projectCode.toUpperCase()}/last-chat-message?pageSize=${pageSize}`,
        cb
      );
    },
    getLastAccessProfiles(
      projectCode: string,
      pageSize: number,
      cb?: Function
    ) {
      this.publicGet(
        `/core/profiles/${projectCode.toUpperCase()}/last-access?pageSize=${pageSize}`,
        cb
      );
    },
    getLastFlame(cb?: Function) {
      this.publicGet(`/sns/flame/last`, cb);
    },
    getLastVisitors(cb?: Function) {
      this.privateGet(`/sns/visitors/${this.getMemberId()}/30`, cb);
    },
    getMessages(cb?: Function) {
      this.privateGet(`/sns/messages/${this.getMemberId()}`, cb);
    },
    getMyTasks(cb?: Function) {
      this.privateGet(`/sns/tasks/${this.getMemberId()}`, cb);
    },
    getMyMission(idMission: string, cb?: Function) {
      this.privateGet(
        `/sns/tasks/missions/${this.getMemberId()}/${idMission}`,
        cb
      );
    },
    getMyMissions(cb?: Function) {
      this.privateGet(`/sns/tasks/missions/${this.getMemberId()}`, cb);
    },
    getNearbyProfiles(projectCode: string, geohash: string, cb?: Function) {
      this.publicGet(
        `/sns/geolocation/nearby/${projectCode.toUpperCase()}/${geohash}`,
        cb
      );
    },
    getNewProfiles(projectCode: string, cb?: Function) {
      this.publicGet(`/core/profiles/${projectCode.toUpperCase()}/new`, cb);
    },
    getPageView(cb?: Function) {
      this.privateGet(`/sns/characters/pageview/${this.getMemberId()}`, cb);
    },
    getProfile(cb?: Function) {
      this.privateGet(
        `/core/profiles/${this.getMemberId()}`,
        (cargo: ICargo) => {
          if (cargo.success) {
            const profile: IProfile = cargo.info;
            if (profile.avator) {
              window.localStorage.setItem('avator', profile.avator);
            }
            if (profile.simpleCode) {
              window.localStorage.setItem('memberNo', profile.simpleCode);
            }
            if (profile.nickname) {
              window.localStorage.setItem('nickname', profile.nickname);
            }
          }
          if (cb) {
            cb(cargo);
          }
        }
      );
    },
    getPublicProfile(simpleCode: string | null, cb?: Function) {
      this.publicGet(`/core/profiles/${simpleCode}`, cb);
    },
    getReviewMission(cb?: Function) {
      this.privateGet(`/sns/tasks/missions/review/${this.getMemberId()}`, cb);
    },
    getSubMembers(simpleCode: string, cb?: Function) {
      this.publicGet(`/sns/hierarchy/sub/profiles/${simpleCode}`, cb);
    },
    getSuggestionProfiles(cb?: Function) {
      this.privateGet(`/core/profiles/pick-for-you`, cb);
    },
    getTasks(cb?: Function) {
      this.publicGet(`/sns/tasks/${projectCode}`, cb);
    },
    getUnreadGuestbooks(cb?: Function) {
      this.privateGet(`/sns/guestbooks/unread/count/${this.getMemberId()}`, cb);
    },
    getUserBanners(memberNo: string, cb?: Function) {
      this.publicGet(`/core/banners/users/${memberNo}`, cb);
    },
    addUserCharacter(idCharacter: Number, cb?: Function) {
      this.privatePost(
        `/sns/characters/${this.getMemberId()}/${idCharacter}`,
        {},
        cb
      );
    },
    delUserCharacter(idCharacter: Number, cb?: Function) {
      this.privateDelete(
        `/sns/characters/${this.getMemberId()}/${idCharacter}`,
        {},
        cb
      );
    },
    getReferralCode(cb?: Function) {
      this.privateGet(`/core/members/referral/${this.getMemberId()}`, cb);
    },
    getReviewMissions(cb?: Function) {
      this.privateGet(`/sns/tasks/missions/review/${this.getMemberId()}`, cb);
    },
    getTopVisitors(cb?: Function) {
      this.privateGet(`/sns/visitors/${this.getMemberId()}/top/30`, cb);
    },
    getUserCharacters(cb?: Function) {
      this.privateGet(`/sns/characters/${this.getMemberId()}`, cb);
    },
    getUserPublicCharacters(sharingCode: string | null, cb?: Function) {
      if (sharingCode == null) {
        return;
      }
      this.publicGet(`/sns/characters/user/${sharingCode}`, cb);
    },
    getVisitors(cb?: Function) {
      this.privateGet(`/sns/visitors/${this.getMemberId()}`, cb);
    },
    leaveMessage(sharingCode: string | null, message: string, cb?: Function) {
      if (sharingCode !== null) {
        this.privatePost(
          `/sns/guestbooks/${sharingCode}/${this.getMemberId()}`,
          { message },
          cb
        );
      }
    },
    login(username: string, password: string, cb?: Function) {
      this.publicPost(
        `/core/sessions/${this.projectCode}`,
        { username, password },
        (cargo: ICargo) => {
          if (cargo.returnCode === '000000') {
            for (let key in cargo.info)
              if (cargo.info[key]) localStorage.setItem(key, cargo.info[key]);
          }
          if (cb !== undefined) {
            cb(cargo);
          }
        }
      );
    },
    logout(cb?: Function) {
      this.privatePost('/core/sessions/expire', {}, (cargo: ICargo) => {
        window.localStorage.removeItem('memberId');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('memberNo');
        window.localStorage.removeItem('sessionId');
        window.localStorage.removeItem('avator');
        window.localStorage.removeItem('expire');
        if (cb !== undefined) {
          cb(cargo);
        }
      });
    },
    postFeedback(message: string, cb?: Function) {
      this.publicPost(`/cs/feedback/${this.projectCode}`, { message }, cb);
    },
    registration(username: string, password: string, cb?: Function) {
      this.publicPost(
        `/core/members/${this.projectCode}`,
        { username, password },
        (cargo: ICargo) => {
          if (cargo.returnCode === '000000') {
            for (let key in cargo.info)
              if (cargo.info[key]) localStorage.setItem(key, cargo.info[key]);
          }
          if (cb !== undefined) {
            cb(cargo);
          }
        }
      );
    },
    submitMission(idMission: number, description: string, cb?: Function) {
      this.privatePost(
        `/sns/tasks/missions/done/${this.getMemberId()}/${idMission}`,
        { description },
        cb
      );
    },
    saveMission(idMission: number, description: string, cb?: Function) {
      this.privatePut(
        `/sns/tasks/missions/done/${this.getMemberId()}/${idMission}`,
        { description },
        cb
      );
    },
    saveMissionCover(idMission: number, cover: string, cb?: Function) {
      this.privatePut(
        `/sns/tasks/missions/done/${this.getMemberId()}/${idMission}`,
        { cover },
        cb
      );
    },
    searchProfile(keyword: string, cb?: Function) {
      this.publicGet(
        `/core/profiles/${projectCode}/search?keyword=${keyword}`,
        cb
      );
    },
    takeUpTask(idTask: number, cb?: Function) {
      this.privateGet(`/sns/tasks/take/${this.getMemberId()}/${idTask}`, cb);
    },
    updateBirthday(birthday: string, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        { birthday },
        cb
      );
    },
    updateGender(gender: string, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        { multiGender: gender },
        cb
      );
    },
    updateMuted(muted: boolean, cb?: Function) {
      this.privatePatch(`/core/profiles/${this.getMemberId()}`, { muted }, cb);
    },
    updateNickname(nickname: string, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        { nickname },
        cb
      );
    },
    updateIntroduction(introduction: string, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        { introduction },
        cb
      );
    },
    updateIsRainbow(isRainbow: boolean, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        { isRainbow },
        cb
      );
    },
    updateCloseProfile(closed: boolean, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        { closed },
        cb
      );
    },
    updateLookFor(lookFor: string, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        { lookFor },
        cb
      );
    },
    updateAvator(avator: string, qualify: boolean, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        { avator, qualify },
        cb
      );
    },
    updateSafeword(safeword: string, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        { safeword },
        cb
      );
    },
    uploadFile(fileOfBlob: Blob, cb?: Function) {
      const formData = new FormData();
      formData.append('file', fileOfBlob);

      fetch('/api/v3/storage', {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json()) // you can do something with response
        .catch((error) => console.error('Error:', error))
        .then((cargo: ICargo) => {
          if (cb !== undefined) {
            cb(cargo);
          }
        });
    },
    uploadImageFile(projectCode: string, fileOfBlob: Blob, cb?: Function) {
      const formData = new FormData();
      formData.append('file', fileOfBlob);

      fetch(
        `/api/v6/private/core/files/${projectCode.toLocaleLowerCase()}/${this.getMemberId()}`,
        {
          method: 'POST',
          headers: {
            'X-Access-Token': window.localStorage.getItem('token') || 'Dummy',
            'X-Member-Id': window.localStorage.getItem('memberId') || '',
          },
          body: formData,
        }
      )
        .then((res) => res.json()) // you can do something with response
        .catch((error) => console.error('Error:', error))
        .then((cargo: ICargo) => {
          if (cb !== undefined) {
            cb(cargo);
          }
        });
    },
    uploadPersonalFile(fileOfBlob: Blob, cb?: Function) {
      const formData = new FormData();
      formData.append('file', fileOfBlob);

      fetch(`/api/v6/private/core/files/${this.getMemberId()}`, {
        method: 'POST',
        headers: {
          'X-Access-Token': window.localStorage.getItem('token') || 'Dummy',
          'X-Member-Id': window.localStorage.getItem('memberId') || '',
        },
        body: formData,
      })
        .then((res) => res.json()) // you can do something with response
        .catch((error) => console.error('Error:', error))
        .then((cargo: ICargo) => {
          if (cb !== undefined) {
            cb(cargo);
          }
        });
    },
    visit(sharingCode: string | null, cb?: Function) {
      if (sharingCode !== null && window.localStorage.getItem('token')) {
        this.privatePost(
          `/sns/visitors/${this.getMemberId()}/${sharingCode}`,
          {},
          cb
        );
      }
    },
  };
};
